import { useLocation } from "react-router-dom";
import logo from "../assets/images/footer/logo.png";

export default function Footer() {
    const location = useLocation();
    const isNewsIndividualPage = location.pathname.includes("news") && !location.pathname.includes("#news");
    return (
        <div className={`py-5 py-md-8 pt-lg-16 pb-lg-10 text-white footer position-relative bg-primary`} style={{transform: isNewsIndividualPage ? "translateY(100px)" : ""}}>
            <div className="container d-flex flex-wrap flex-column flex-lg-row justify-content-between">
                <div className="d-flex justify-content-center">
                    <img src={logo} alt="logo" className="footer-logo w-lg-75 w-xxl-100"></img>
                </div>
                <div className="d-flex flex-column justify-content-end mt-3 text-center">
                    <p>COPYRIGHT © 中華民國產業發展聯合總會  All right reserved.</p>
                </div>
            </div>
        </div>
    )
}