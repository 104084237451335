import React, { lazy, useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import "bootstrap/dist/js/bootstrap";

import Header from "./components/Header";
import Footer from "./components/Footer";
import SuspenseComponent from "./components/Suspense";
const Homepage = lazy(() => import("./pages/Homepage"));
const IndividualNews = lazy(() => import("./pages/IndividualNews"));
const Top = lazy(() => import("./components/Top"));

function App() {
  const [ showTopButton, setShowTopButton ] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 500) {
        setShowTopButton(true);
      } else {
        setShowTopButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="min-vh-100 d-flex flex-column">
        <Header />
        <Routes>
          <Route exact path="/" element={
            <React.Suspense fallback={<SuspenseComponent />}>
              <Homepage />
            </React.Suspense>}>
          </Route>
          <Route exact path="/news/:id" element={
            <React.Suspense fallback={<SuspenseComponent />}>
              <IndividualNews />
            </React.Suspense>}>
          </Route>
        </Routes>
        <Top showTopButton={showTopButton} />
        <Footer />
    </div>
  );
}

export default App;