import { useLocation } from "react-router-dom";

export default function SuspenseComponent() {
    const location = useLocation();
    let bg;

    switch (location.pathname) {
        case "/projects":
            bg = "primary";
            break;
        default:
            bg = "white";
    }

    return (
        <div className={`bg-${bg}`} style={{height: "calc(100vh - 102px)"}}></div>
    )
}