import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import Footer from "../components/Footer";

import arrow from "../assets/images/header/arrow.png";
// import offcanvasBg from "../assets/images/header/canvas-bg.png";
import offcanvasBg from "../assets/images/header/Group 34(1).png";

export default function Header() {
    const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
    const { hash } = useLocation();

    useEffect(() => {
        if (hash) {
            const element = document.querySelector(hash);
            if (element && hash === "/") {
                window.scrollTo({ top: 0, behavior: "instant" });
            } else if (element) {
                let offset;
                if (window.innerWidth < 576) {
                    offset = hash === "#contact" ? 70 : -44; // 減去 navbar 高度
                } else if (window.innerWidth < 768) {
                    offset = hash === "#contact" ? 40 : -66.5; // 減去 navbar 高度
                } else if (window.innerWidth < 992) {
                    offset = hash === "#contact" ? 20 : -90; // 減去 navbar 高度
                } else {
                    offset = ["#about", "#contact"].includes(hash) ? 0 : -74.5;
                }
                const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
                window.scrollTo({
                  top: elementPosition + offset,
                  behavior: 'smooth'
                });
            }  
        }
    }, [hash]);

    const handleCloseOffcanvas = () => {
        const closeBtn = document.querySelector("#offcanvasCloseBtn");
        if (closeBtn) {
            closeBtn.click();
        }
    }

    useEffect(() => {
      const offcanvasElement = document.getElementById('offcanvas');
  
      const handleOpenChange = () => {
        if (isOffcanvasOpen) {
          offcanvasElement.classList.add('show');
        } else {
          offcanvasElement.classList.remove('show');
        }
      };
  
      handleOpenChange();
  
      return () => {
        offcanvasElement.classList.remove('show');
      };
    }, [isOffcanvasOpen]);
  
    const toggleOffcanvas = () => {
      setIsOffcanvasOpen(!isOffcanvasOpen);
    };
    
    return (
        <nav className={`min-vh-lg-10 py-3 py-md-30px py-lg-0 position-sticky top-0 z-10 d-flex justify-content-center buttonMenu-zh header-navbar bg-white`}>
            <div className="row d-flex justify-content-center w-100">
                <div className="container d-flex justify-content-between">
                    <div className="py-lg-4">
                        <h1>
                            <Link to="/" className="logo-link" onClick={() => window.scrollTo(0, 0)} >中華民國產業發展聯合總會</Link>
                        </h1>
                    </div>
                    {/* 桌機 */}
                    <ul className="d-none d-lg-flex menuButton header-ul" style={{listStyleType: "none"}}>
                        <li className="li-link me-4 me-2">
                            <Link to="/#about" className={`d-flex justify-content-center align-items-center w-100 h-100`}>總會宗旨/任務</Link>
                        </li>
                        <li className="li-link me-4 me-2">
                            <Link to="/#organizational" className={`d-flex justify-content-center align-items-center w-100 h-100`}>組織架構/會員</Link>
                        </li>
                        <li className="li-link me-4 me-2">
                            <Link to="/#news" className={`d-flex justify-content-center align-items-center w-100 h-100`}>最新消息</Link>
                        </li>
                        <li className="li-link me-4 me-2">
                            <Link to="/#taiwan-industrial-ai-transformation-institute" className={`d-flex justify-content-center align-items-center w-100 h-100`}>台灣產業Al轉型學院</Link>
                        </li>
                        <li className="li-link">
                            <Link to="/#contact" className={`d-flex justify-content-center align-items-center w-100 h-100`}>聯絡我們</Link>
                        </li>
                    </ul>
                    {/* 手機、平板 */}
                    <div className="d-flex d-lg-none align-items-center" style={{marginLeft: "-32px"}}>
                        <button id="offcanvasCloseBtn" className="btn hamburgerMenuBtn d-flex flex-column justify-content-between" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" aria-controls="offcanvas" onClick={toggleOffcanvas}>
                            <div className={`hamburgerMenuLine`}></div>
                            <div className={`hamburgerMenuLine`}></div>
                            <div className={`hamburgerMenuLine`}></div>
                        </button>

                        <div className="offcanvas offcanvas-end d-lg-none border-0 w-100 header-offcanvas" tabindex="-1" id="offcanvas" aria-labelledby="offcanvasLabel">
                            <div className="offcanvas-header px-0 py-3">
                                <div className="container d-flex justify-content-between align-items-center">
                                    <Link to="/" className="logo-link d-sm-none" onClick={() => window.scrollTo(0, 0)}>中華民國產業發展聯合總會</Link>
                                    <Link to="/" className="logo-link d-none d-sm-block" onClick={() => window.scrollTo(0, 0)}>中華民國產業發展聯合總會</Link>
                                    <button type="button" className="btn text-reset p-0" data-bs-dismiss="offcanvas" aria-label="Close" style={{width: "24px", height: "24px"}}>
                                        <span className="material-symbols-outlined text-secondary">
                                            close
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="offcanvas-body px-0 position-relative" style={{background: "#129A9D"}}>
                                <div className="">
                                    <img src={offcanvasBg} alt="background" className="position-absolute bottom-0"></img>
                                </div>
                                <div className="container position-relative" style={{zIndex: "10"}}>
                                    <ul className="w-100 h-100 px-3 d-flex flex-column buttonMenu-zh fw-normal mt-16" style={{listStyleType: "none"}}>
                                        <li className="li-link white-space-nowrap border-start border-8 border-secondary ps-2 d-flex align-items-center cursor-pointer">
                                            <Link to="/#about" onClick={handleCloseOffcanvas} className="text-white" style={{fontSize: "1.375rem", fontWeight: "300"}}>總會宗旨/任務</Link>
                                            <img src={arrow} alt="learn more" className="ms-3" style={{width: "11px", height: "16px", transform: "translateY(1px)"}}></img>
                                        </li>
                                        <li className="li-link white-space-nowrap mt-11 border-start border-8 border-secondary ps-2 d-flex align-items-center cursor-pointer">
                                            <Link to="/#organizational" onClick={handleCloseOffcanvas} className="text-white" style={{fontSize: "1.375rem", fontWeight: "300"}}>組織架構/會員</Link>
                                            <img src={arrow} alt="learn more" className="ms-3" style={{width: "11px", height: "16px", transform: "translateY(1px)"}}></img>
                                        </li>
                                        <li className="li-link white-space-nowrap mt-11 border-start border-8 border-secondary ps-2 d-flex align-items-center cursor-pointer">
                                            <Link to="/#news" onClick={handleCloseOffcanvas} className="text-white" style={{fontSize: "1.375rem", fontWeight: "300"}}>最新消息</Link>
                                            <img src={arrow} alt="learn more" className="ms-3" style={{width: "11px", height: "16px", transform: "translateY(1px)"}}></img>
                                        </li>
                                        <li className="li-link white-space-nowrap mt-11 border-start border-8 border-secondary ps-2 d-flex align-items-center cursor-pointer">
                                            <Link to="/#taiwan-industrial-ai-transformation-institute" onClick={handleCloseOffcanvas} className="text-white" style={{fontSize: "1.375rem", fontWeight: "300"}}>台灣產業Al轉型學院</Link>
                                            <img src={arrow} alt="learn more" className="ms-3" style={{width: "11px", height: "16px", transform: "translateY(1px)"}}></img>
                                        </li>
                                        <li className="li-link white-space-nowrap mt-11 border-start border-8 border-secondary ps-2 d-flex align-items-center cursor-pointer">
                                            <Link to="/#contact" onClick={handleCloseOffcanvas} className="text-white" style={{fontSize: "1.375rem", fontWeight: "300"}}>聯絡我們</Link>
                                            <img src={arrow} alt="learn more" className="ms-3" style={{width: "11px", height: "16px", transform: "translateY(1px)"}}></img>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}