// polyfill for react-pdf
if (typeof Promise.withResolvers !== 'function') {
    Promise.withResolvers = function () {
      let resolve, reject;
      const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      });
      return { promise, resolve, reject };
    };
}

// polyfill for String.replaceAll() function (since it's not working in Line)
if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function(str, newStr) {
    // 如果 str 是正則表達式，需要特殊處理
    if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
      return this.replace(str, newStr);
    }
    return this.replace(new RegExp(str, 'g'), newStr);
  };
}